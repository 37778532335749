<template>
  <div>
    <tabBar :cardIndex="500" :mouseenterIndex="20" style="height: 90px" />
    <div class="ckoseCenter">
      <div class="cenrt">
        <div class="rightcenter">
          <steps :stepInfo="stepInfo"></steps>
        </div>
        <div class="titles">选择收货地址</div>
        <div class="positcenter">
          <el-row :gutter="20">
            <el-col :span="6" v-for="(item, index) in addressList" :key="index">
              <div
                @click="clickposis(index, item)"
                :class="active == index ? 'litcener baces' : 'litcener'"
              >
                <div class="morens" v-if="item.isDefault">默认地址</div>
                <div class="names">
                  <span>{{ item.name }}</span>
                  <span class="phone">{{ item.mobile }}</span>
                </div>
                <div class="infoposis">
                  {{ item.consigneeAddressPath }},{{ item.detail }}
                </div>
                <img
                  class="sanjiaos"
                  v-if="active == index"
                  src="../assets/img/sanjiao.png"
                  alt=""
                />
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="allposis">
          <span class="lookes" v-if="istoall" @click="lookalls"
            >查看全部地址</span
          ><img
            class="gemgdip"
            v-if="istoall"
            src="../assets/img/gengduo.png"
            alt=""
          />
          <span class="guanlis" @click="toppages">管理收货地址</span>
        </div>
        <div class="titles">商品信息</div>
        <div style="border: 1px solid #dcdcdc; margin-top: 18px">
          <el-table :data="goodsListObj.checkedSkuList" style="width: 100%">
            <el-table-column prop="goodsSku" label="商品" width="500">
              <template slot-scope="scope">
                <div class="goodslist">
                  <img
                    class="goodsimg"
                    :src="scope.row.goodsSku.thumbnail"
                    alt=""
                  />
                  <div class="goodsright">
                    <div class="gootitle">
                      {{ scope.row.goodsSku.goodsName }}
                    </div>
                    <div class="skulsi">
                      {{ scope.row.goodsSku.grade
                      }}{{ scope.row.goodsSku.goodsUnit }}
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="utilPrice"
              label="单价"
              width="120"
              align="purchasePrice"
              v-if="way != 'POINTS'"
            >
            </el-table-column>
            <el-table-column
              prop="point"
              label="单价"
              width="90"
              align="center"
              v-if="way == 'POINTS'"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.point }}积分</span>
              </template>
            </el-table-column>
            <el-table-column label="数量" width="200" align="center">
              <template slot-scope="scope">
                <el-input-number
                  size="mini"
                  v-model="scope.row.num"
                  @change="handleChange($event, scope.row)"
                  :min="1"
                  label="描述文字"
                ></el-input-number>
              </template>
            </el-table-column>
            <!-- <el-table-column
              label="优惠"
              width="120"
              align="center"
              v-if="way != 'POINTS'"
            >
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.selearrvalue"
                  size="mini"
                  placeholder="请选择"
                  @change="changeLisr"
                >
                  <el-option
                    v-for="item in scope.row.selearr"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </template>
            </el-table-column> -->
            <el-table-column
              prop="subTotal"
              label="小计"
              align="center"
              v-if="way != 'POINTS'"
            >
            </el-table-column>
            <el-table-column
              prop="priceDetailDTO"
              label="小计"
              align="center"
              v-if="way == 'POINTS'"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.priceDetailDTO.payPoint }}积分</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="maidjialiouyan">
          <div class="texts">给卖家留言：</div>
          <div class="textcenter">
            <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 6 }"
              placeholder="请输入内容"
              v-model="infotos"
            >
            </el-input>
          </div>
          <div class="textright">
            <div class="maidjialiouyantop" v-if="way != 'POINTS'">
              <div class="texts">选择优惠券：</div>
              <div class="adsslies">
                <el-select v-model="goodsListObj.slesValue" placeholder="请选择" @change="changeLisr">
                  <el-option
                    v-for="item in goodsListObj.options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="flesa">
              <div class="prices">商品总价：</div>
              <div class="prices1" v-if="way != 'POINTS'">
                ￥{{ goodsListObj.priceDetailDTO.goodsPrice }}
              </div>
              <div
                class="prices1"
                v-if="way == 'POINTS'"
                style="color: #00a3e0; font-size: 14px"
              >
                {{ goodsListObj.priceDetailDTO.payPoint }}积分
              </div>
            </div>
            <div class="flesa">
              <div class="prices">运营(快递)：</div>
              <div class="prices1">
                ￥{{ goodsListObj.priceDetailDTO.freightPrice }}
              </div>
            </div>
            <div class="flesa">
              <div class="prices">优惠金额：</div>
              <div class="prices1">
                ￥{{ goodsListObj.priceDetailDTO.couponPrice }}
              </div>
            </div>
            <div class="flesa">
              <div class="prices">实际付款：</div>
              <div class="prices1" v-if="way != 'POINTS'">
                ￥{{ goodsListObj.priceDetailDTO.billPrice }}
              </div>
              <div
                class="prices1"
                v-if="way == 'POINTS'"
                style="color: #00a3e0; font-size: 14px"
              >
                {{ goodsListObj.priceDetailDTO.payPoint }}积分
              </div>
            </div>
          </div>
        </div>
        <div class="titles" style="margin-top: 10px" v-if="way != 'POINTS' || goodsListObj.priceDetailDTO.billPrice != 0">
          选择支付方式
        </div>
        <div class="zhifu" v-if="way != 'POINTS'">
          <!-- <div class="wecart">
            <img class="cartimg" src="../assets/img/weiixn.png" alt="" />
            <el-radio v-model="radio" label="WECHAT">微信支付</el-radio>
          </div> -->
          <div class="alply">
            <img class="alplyimg" src="../assets/img/zhifubao.png" alt="" />
            <el-radio v-model="radio" label="ALIPAY">支付宝支付</el-radio>
          </div>
        </div>
        <img
          v-if="wecartimg"
          style="
            width: 150px;
            height: 150px;
            margin-left: 10px;
            margin-top: 20px;
          "
          :src="imgurl + wecartimg"
          alt=""
        />
        <div class="btnsaaa" @click="addforms">提交订单</div>
      </div>
    </div>
    <footers class="foos"></footers>
  </div>
</template>

<script>
import tabBar from "@/components/tabBar.vue";
import footers from "@/components/footer.vue";
import steps from "./mepages/step.vue";
import http from "@/request/http";
import {
  getmemberAddress,
  getshippingAddress,
  getshippingcartschecked,
  getcouponcanUse,
  postcartsnum,
  postcreatetrade,
  paycashier,
  getcashierresult,
  cartsselectcoupon,
} from "../request/homeApi";
export default {
  name: "closeORaccountpage",
  components: {
    tabBar,
    footers,
    steps,
  },
  data() {
    return {
      wecartimg: "",
      infotos: "",
      radio: "",
      tableData: [],
      stepInfo: {
        list: [
          {
            status: "拍下商品",
            statusTime: "",
          },
          {
            status: "填写核对订单信息",
            statusTime: "",
          },
          {
            status: "成功提交订单",
            statusTime: "",
          },
        ],
        step: 2,
        width: 20,
      },
      active: 0,
      params: {
        pageNumber: 1,
        pageSize: 4,
        sort: "is_default",
        order: "desc",
      },
      addressList: [],
      total: 0,
      istoall: true,
      goodsListObj: {},
      way: "",
      tiems: null,
      tedSn: "",
    };
  },
  mounted() {
    this.imgurl = http.baseURL;
    this.way = this.$route.query.way;
    this.addressApi();
    this.getshippingcartscheckedAPI();
    clearInterval(this.tiems);
  },
  destroyed: function () {
    clearInterval(this.tiems);
  },
  methods: {
    changeLisr(e) {
      let data = {
        memberCouponId: e,
        used: true,
        way: this.way,
      };
      cartsselectcoupon(data)
        .then((resqas) => {
          console.log(resqas);
          if (resqas.code == 200) {
            this.getshippingcartscheckedAPI();
          }
        })
        .catch((res) => {
          console.log("请求失败");
        });
    },
    getUrlKey(name, url) {
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            url
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },
    //   提交订单
    addforms() {
      if (this.tedSn) {
        clearInterval(this.tiems);
        let strings = {
          clientType: "PC",
          orderType: "TRADE",
          paymentClient: "NATIVE",
          paymentMethod: this.radio,
          sn: this.tedSn,
        };
        paycashier(strings)
          .then((resqas) => {
            console.log(resqas);
            if (resqas.code == 200) {
              if (this.radio == "WECHAT") {
                this.wecartimg = resqas.result;
                this.tiems = setInterval(() => {
                  this.getcashierresultApi(res.result.sn);
                }, 1000);
              } else {
                document.querySelector("body").innerHTML = resqas.result;
                document.forms[0].submit();
              }
            }
          })
          .catch((res) => {
            console.log("请求失败");
          });
        return;
      }
      if (this.way != "POINTS" && this.goodsListObj.priceDetailDTO.billPrice != 0) {
        if (this.radio == "") {
          this.$message.error("请先选择支付方式");
          return;
        }
      }

      let data = {
        client: "PC",
        remark: [
          {
            remark: this.infotos,
            storeId: this.goodsListObj.checkedSkuList[0].storeId,
          },
        ],
        way: this.way,
      };
      postcreatetrade(data)
        .then((res) => {
          console.log(res);

          if (res.code == 200) {
            if (this.way == "POINTS" || this.goodsListObj.priceDetailDTO.billPrice == 0) {
              setTimeout(()=>{
                this.$router.push({ path: "/mepages/myoder?active=1" });
              },500)
              return;
            }
            this.tedSn = res.result.sn;
            let strings = {
              clientType: "PC",
              orderType: "TRADE",
              paymentClient: "NATIVE",
              paymentMethod: this.radio,
              sn: res.result.sn,
            };
            paycashier(strings)
              .then((resqas) => {
                console.log(resqas);
                if (resqas.code == 200) {
                  if (this.radio == "WECHAT") {
                    this.wecartimg = resqas.result;
                    this.tiems = setInterval(() => {
                      this.getcashierresultApi(res.result.sn);
                    }, 1000);
                  } else {
                    document.querySelector("body").innerHTML = resqas.result;
                    document.forms[0].submit();
                  }
                }
              })
              .catch((res) => {
                console.log("请求失败");
              });
          }
        })
        .catch((res) => {
          console.log("请求失败");
        });
    },
    getcashierresultApi(sn) {
      let obj = {
        clientType: "PC",
        orderType: "TRADE",
        sn,
      };
      getcashierresult(obj)
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            if (res.result) {
              clearInterval(this.tiems);
              this.$router.push({ path: "/paymentsuccess" });
            }
          }
        })
        .catch((res) => {
          clearInterval(this.tiems);
        });
    },
    //   获取优惠券
    optionsselect(item, result) {
      // console.log(item, "222222222");
      let obj = {
        pageNumber: 1,
        pageSize: 200,
        storeId: item.storeId,
        totalPrice: item.subTotal,
        scopeId: item.goodsSku.id, //skuid
      };
      getcouponcanUse(obj)
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            item.selearr = [];
            item.selearrvalue = "";
            res.result.records.forEach((itemsad) => {
              result.canUseCoupons.forEach((emts) => {
                if (emts.id == itemsad.id) {
                  item.selearrvalue =
                    "满" + itemsad.consumeThreshold + "-" + itemsad.price;
                }
              });
              let strig = {
                value: itemsad.id,
                label: "满" + itemsad.consumeThreshold + "-" + itemsad.price,
              };
              item.selearr.push(strig);
            });
          }
        })
        .catch((res) => {
          console.log("请求失败");
        });
    },
    //   修改数量
    handleChange(e, item) {
      console.log(e, item.goodsSku.id);
      let data = {
        skuId: item.goodsSku.id,
        num: e,
        cartType: this.way,
      };
      postcartsnum(data)
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.getshippingcartscheckedAPI();
          }
        })
        .catch((res) => {
          console.log("请求失败");
        });
    },
    //   获取商品信息
    getshippingcartscheckedAPI() {
      let obj = {
        way: this.way,
      };
      getshippingcartschecked(obj)
        .then((res) => {
          if (res.code == 200) {
            if (res.result.checkedSkuList.length == 0) {
              this.$router.push({ path: "/mepages/myoder?active=1" });
            }
            // res.result.checkedSkuList.forEach((item) => {
            //   this.optionsselect(item, res.result);
            // });
            res.result.options = [];
            res.result.slesValue = "";
            res.result.canUseCoupons.forEach((item)=>{
              if(res.result.platformCoupon && item.id == res.result.platformCoupon.memberCoupon.id ){
                res.result.slesValue = '满'+item.consumeThreshold + '-'+item.price;
              }
              let obg = {
                label: '满'+item.consumeThreshold + '-'+item.price,
                value: item.id
              };
              res.result.options.push(obg)
            })
            setTimeout(() => {
              this.goodsListObj = res.result;
            }, 1000);
          }
        })
        .catch((res) => {
          console.log("请求失败");
        });
    },
    //   管理收货地址
    toppages() {
      this.$router.push({ path: "/mepages/shippingaddress?active=1" });
    },
    //   查看全部地址
    lookalls() {
      this.params.pageSize = this.total;
      this.addressApi();
      this.istoall = false;
    },
    //   获取会员地址
    addressApi() {
      getmemberAddress(this.params)
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.total = res.result.total;
            if (res.result.records.length == 1) {
              this.clickposis(0, res.result.records[0]);
            } else if (res.result.records.length > 1) {
              var result = res.result.records.some(function (item) {
                if (item.isDefault) {
                  return true;
                }
              });
              if (!result) {
                this.clickposis(0, res.result.records[0]);
              }
            }
            this.addressList = res.result.records;
          }
        })
        .catch((res) => {
          console.log("请求失败");
        });
    },
    clickposis(index, item) {
      let obg = {
        shippingAddressId: item.id,
        way: this.way,
      };
      getshippingAddress(obg)
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            // this.$message.success("操作成功！");
            this.getshippingcartscheckedAPI();
            this.active = index;
          }
        })
        .catch((res) => {
          console.log("请求失败");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.ckoseCenter {
  width: 100%;
  background: #f8f8f8;
  overflow: hidden;
  .cenrt {
    width: 1053px;
    margin: 30px auto 83px;
    padding: 40px 30px;
    background: #ffffff;
    box-shadow: 0px 8px 7px 1px rgba(55, 55, 55, 0.01);
    .rightcenter {
    }
    .titles {
      font-size: 14px;
      color: #535353;
    }
    .positcenter {
      margin-top: 18px;
      .litcener {
        width: 100%;
        // height: 120px;
        padding: 15px;
        background: url("../assets/img/huise.png") no-repeat;
        background-size: 100% 100%;
        font-size: 14px;
        position: relative;
        cursor: pointer;
        .names {
          color: #535353;
        }
        .infoposis {
          margin-top: 20px;
          color: #818181;
        }
        .sanjiaos {
          width: 30px;
          height: 30px;
          position: absolute;
          bottom: 0;
          right: 0;
        }
        .morens {
          position: absolute;
          top: 0;
          right: 0;
          display: inline-block;
          padding: 5px;
          background: #dcdcdc;
          border-radius: 2px;
          color: #ffffff;
          font-size: 12px;
        }
      }
      .baces {
        background: url("../assets/img/caise.png") no-repeat;
        background-size: 100% 100%;
      }
    }
    .allposis {
      text-align: right;
      font-size: 14px;
      margin-top: 20px;
      .lookes {
        cursor: pointer;
        color: #818181;
      }
      .gemgdip {
        width: 10px;
        height: 8px;
        margin-right: 2px;
      }
      .guanlis {
        color: #00a3e0;
        padding-left: 30px;
        cursor: pointer;
      }
    }
    .maidjialiouyan {
      display: flex;
      justify-content: flex-start;
      margin-top: 17px;
      padding: 24px 18px;
      background: #efefef;
      font-size: 14px;
      .texts {
        width: 85px;
        color: #535353;
      }
      .textcenter {
        width: 65%;
      }
      .textright {
        width: 25%;
        color: #535353;
        text-align: right;
        .maidjialiouyantop{
          display: flex;
          justify-content: flex-end;
          align-content: center;
          padding: 10px 0;
          .texts{
            margin-top: 20rpx;
          }
          .adsslies{
            width: 58%;
          }
        }
        .flesa {
          display: flex;
          justify-content: flex-end;
          align-content: center;
          .prices {
            color: #535353;
          }
          .prices1 {
            width: 80px;
          }
        }
      }
    }
    .zhifu {
      margin-top: 30px;
      display: flex;
      align-items: center;
      .wecart {
        display: flex;
        align-items: center;
        margin-right: 33px;
        .cartimg {
          width: 38px;
          height: 34px;
          margin-right: 11px;
        }
      }
      .alply {
        display: flex;
        align-items: center;
        .alplyimg {
          width: 34px;
          height: 34px;
          margin-right: 11px;
        }
      }
    }
    .btnsaaa {
      width: 128px;
      height: 40px;
      line-height: 40px;
      margin: 0 auto;
      margin-top: 44px;
      text-align: center;
      background: #00a3e0;
      border-radius: 2px;
      font-size: 14px;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
.goodslist {
  display: flex;
  align-items: center;
  .goodsimg {
    width: 130px;
    height: 72px;
  }
  .goodsright {
    width: 250px;
    height: 72px;
    margin-left: 10px;
    .gootitle {
      width: 100%;
      font-size: 14px;
      color: #535353;
    }
    .skulsi {
      font-size: 14px;
      color: #818181;
    }
  }
}
</style>